import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "@layouts/index"
import SEO from "@components/seo"

import pageCss from "@css/components/common/page.module.styl"
import missionCss from "@css/components/recruit/mission.module.styl"
import memberCss from "@css/components/recruit/member.module.styl"
import wantedCss from "@css/components/recruit/wanted.module.styl"

import Footer from '@components/Footer'


const MemberList = ({data, imagesData}) => {
    let list = []

    let getImage = (id) => {
        let hoge = imagesData.map( row => {
            let name = row.node.fluid.originalName
            let reg = new RegExp(id)
            if( name.match(reg)){
                // console.log(row.node.fluid)
                return row.node.fluid
            }
        })
        return hoge.filter(v => v)[0]
    }

    for(var i in data){
        let row = data[i].node
        let fluid = getImage(`${row.img_id}`)
        // console.log(fluid)
        list.push(
            <li className={memberCss.listItem} key={i}>
                <div className={memberCss.image}>
                    { fluid && <Image fluid={fluid}/>}
                </div>
                <div className={memberCss.position}>{row.position}</div>
                <div className={memberCss.name}>{row.name}</div>
            </li>
        )
    }
    return(
        <ul className={memberCss.list}>
            {list}
        </ul>
    )
}


class Recruit extends React.PureComponent {
    render(){
        const { location, data } = this.props
        const {
            recruit__img__top,
            recruit__img__tool,
            recruit__img__recruit,
            bg01,
            member__img__matui,
            allMemberJson,
            memberImages
        } = data
        return(
            <div>
                <SEO title="RECRUIT" />
                <BackgroundImage className={pageCss.bg} fluid={bg01.childImageSharp.fluid}/>
                <main>
                    <div className={pageCss.container}>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <p className={pageCss.label}>RECRUIT</p>
                                <h2 className={pageCss.title_big}>
                                    <span>求む、議論が</span>
                                    <span>できる</span>
                                    <br className="pc"/>
                                    <span>成長途中の</span>
                                    <span>開発者たち。</span>
                                </h2>
                            </div>
                            <div className={pageCss.content}>
                                <div className={pageCss.paragraphs}>
                                    <p className={pageCss.spNoMargin}>
                                        SUGAR株式会社では、「革新的なプロダクト」を「理にかなった方法」で開発しております。
                                    </p>
                                    <p className={pageCss.spNoMargin}>
                                        「プレスリリース駆動開発」といったその場しのぎの短絡的な手段は使わず、「ドメイン駆動開発」などの道具を使って常に長期的な視野に立ったプロダクト開発を進めています。
                                    </p>
                                </div>
                                <div className={pageCss.slider}>
                                    <Image fluid={recruit__img__top.childImageSharp.fluid}/>
                                </div>
                            </div>
                        </section>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <p className={pageCss.label}>TOOL</p>
                                <h2 className={pageCss.title}>開発ツール群</h2>
                            </div>
                            <div className={pageCss.content}>
                                <div className={pageCss.picture}>
                                    <Image fluid={recruit__img__tool.childImageSharp.fluid}/>
                                </div>
                                {/*<div className={pageCss.paragraphs}>
                                    <p>
                                        SUGAR株式会社では、「革新的なプロダクト」を「理にかなった方法」で開発しております。「プレスリリース駆動開発」といったその場しのぎの短絡的な手段は使わず、「ドメイン駆動開発」などの道具を使って常に長期的な視野に立ったプロダクト開発を進めています。
                                    </p>
                                </div>*/}
                            </div>
                        </section>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <p className={pageCss.label}>MISSION</p>
                                <h2 className={pageCss.title__center}>
                                    <span>おどろきを、</span><br className="sp"/>
                                    <span>つねに。</span>
                                </h2>
                            </div>
                            <div className={pageCss.content}>
                                <div className={missionCss.container}>
                                    {/*<h3 className={missionCss.title}>
                                        <span>おどろきを、</span>
                                        <span>つねに。</span>
                                    </h3>*/}
                                    <p className={missionCss.text}>
                                        おどろき、とは何でしょうか。<br/>
                                        おどろき、とはあらゆる感情の起点です。<br className="pc"/>
                                        おどろき、とはすべての生き物が持つ異質な心理状態です。<br className="pc"/>
                                        おどろき、とは予想が外れた時に産まれる特殊な感情です。<br/>
                                        <br/>
                                        SUGARのスタートは人々があっ、とおどろくサービスを作ることが原点でした。<br className="pc"/>
                                        考えていたことはまさに、今までにあるものではなく、だれもが感じる「未来」のサービスを作りたい。<br/>
                                        <br/>
                                        おどろくとは先述の通り、人々の予想を上回らない限り起こりえない状態です。<br className="pc"/>
                                        つまりわたしたちはスタート地点から人々の予想を超え続ける事が、<br className="pc"/>
                                        原点で決められたこの会社の方向性だったと考えています。<br/>
                                        <br/>
                                        あたりまえのことをあたりまえに行うだけでは破壊的な価値創造は難しい世の中に突入しています。<br className="pc"/>
                                        SUGARは常に、ひとびとの予想を超える価値創造の集団であり続けます。
                                    </p>
                                    {/*<div className={missionCss.ceo}>
                                        <div className={missionCss.ceo__image}>
                                            <Image fluid={member__img__matui.childImageSharp.fluid}/>
                                        </div>
                                        <div className={missionCss.ceo__position}>代表取締役CEO</div>
                                        <div className={missionCss.ceo__name}>松井 厚憲</div>
                                    </div>*/}
                                </div>
                            </div>
                        </section>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <p className={pageCss.label}>MEMBER</p>
                                <h2 className={pageCss.title}>メンバー紹介</h2>
                            </div>
                            <div className={pageCss.content}>
                                <div className={memberCss.container}>
                                    <MemberList data={allMemberJson.edges} imagesData={memberImages.edges}/>
                                </div>
                            </div>
                        </section>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <p className={pageCss.label}>WANTED</p>
                                <h2 className={pageCss.title}>求人情報</h2>
                            </div>
                            <div className={pageCss.content}>
                                <div className={pageCss.slider}>
                                <BackgroundImage className={wantedCss.container} fluid={recruit__img__recruit.childImageSharp.fluid}>
                                    <a className={wantedCss.inner}
                                        href='https://www.wantedly.com/companies/sugarcorp'
                                        target='_blank'
                                    >
                                        <div className={wantedCss.block}>
                                            {/*<p className={wantedCss.text}>
                                            </p>*/}
                                            <span className={wantedCss.button}>
                                                求人情報はこちら
                                            </span>
                                        </div>
                                    </a>
                                </BackgroundImage>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}

export default Recruit

export const query = graphql`
    query {
        allMemberJson {
            edges {
                node {
                    name
                    position
                    img_id
                    desc
                }
            }
        }

        memberImages : allImageSharp(filter: {fixed: {originalName:{regex:"/(member)/"}}}) {
              edges {
                node {
                    id
                    fluid(maxWidth: 400, maxHeight: 400) {
                        originalName
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }

        recruit__img__top : file(relativePath: { eq: "recruit__img__top__new.png" }) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        recruit__img__recruit : file(relativePath: { eq: "recruit__img__recruit-1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        recruit__img__tool : file(relativePath: { eq: "recruit__img__tool@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 724) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bg01 : file(relativePath: { eq: "global__img__sugar-bg-02@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 842) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        member__img__matui : file(relativePath: { eq: "member__img__matui_atunori.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
